import React from "react"
// import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { ScrollComponent, TitleAnimation } from '../../index'
import { LinkButton } from "../../index"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import * as styles from "./News.module.scss"
import SingleList from "../../SingleList/SingleList"

const News = () => {

  const breakpoints = useBreakpoint();

  const title1 = [
    { "id": 0, "text": "n" },
    { "id": 1, "text": "e" },
    { "id": 2, "text": "w" },
    { "id": 3, "text": "s" },
  ];

  return (
    <ScrollComponent>
      <div className={styles.News__wrp}>
        <div className={styles.News}>
          <TitleAnimation data={title1} className={`titleRubikCenter`} />
          <h2 className={`subH2`}>お知らせ</h2>
          <div className={styles.contents}>
            <SingleList className={styles.List} />
          </div>
        </div>
        <LinkButton link="/news/">お知らせ一覧</LinkButton>
        {
          breakpoints.sm ?
            null
            :
            <div className={styles.Obi}>
              <span className={styles.ObiEn}>recent news</span><span className={styles.ObiJa}>最新のお知らせ</span>
            </div>
        }

      </div>
    </ScrollComponent>
  )
}
export default News