import React from "react";
import { StaticImage } from "gatsby-plugin-image"; //ローカルから画像を持ってくる
import { ScrollComponent } from "../../index";
import * as styles from "./MainLead.module.scss";
// import styled from 'styled-components';
//背景画像の設定
// import stepBgImg from '../../../images/top/deco01.png'
//足跡
import BirdSteps from "../../BirdSteps/BirdSteps";
import stepsData from "../../BirdSteps/stepsData.json";
// import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const MainLead = () => {
  //足跡アニメーションのデータを読み込む
  const steps = stepsData.step1;
  //ブレークポイントの設定
  // const breakpoints = useBreakpoint();

  return (
    <div className={styles.MainLead}>
      <div className={styles.col1}>
        <ScrollComponent>
          <StaticImage src="../../../images/top/lead/top_lead-1.png" alt="Main Lead" placeholder="blurred" />
        </ScrollComponent>
      </div>
      <div className={styles.col2}>
        <ScrollComponent>
          <h1>
            人にも鶏にも
            <br />
            自然にもやさしく。
            <br />
            これが深川養鶏の
            <br />
            モットーです。
          </h1>
          <p className={`lead`}>
            私たち深川養鶏は、みなさんに鶏肉や加工品、
            <br />
            お菓子をおいしく安全にお届けすることはもちろん、
            <br />
            養鶏のプロフェショナルとして飼育から加工、消費、
            <br />
            そしてその過程で自然に与える影響までをより良いものにするべく
            <br />
            日々仕事に取り組んでいます。
          </p>
        </ScrollComponent>
      </div>
      <div className={styles.col3}>
        <ScrollComponent>
          <StaticImage src="../../../images/top/lead/top_lead-4.png" alt="Main Lead" placeholder="tracedSVG" />
        </ScrollComponent>
      </div>
      <div className={styles.BirdSteps}>
        <BirdSteps data={steps} />
      </div>
    </div>
  );
};
export default MainLead;
