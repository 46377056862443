import React from 'react'
import "./ModalCloseBtn.scss"

const ModalCloseBtn = () => {
  return (
    <div id="_108">
      <svg className="_93">
        <rect id="_93" rx="0" ry="0" x="0" y="0" width="28" height="3">
        </rect>
      </svg>
      <svg className="_94">
        <rect id="_94" rx="0" ry="0" x="0" y="0" width="28" height="3">
        </rect>
      </svg>
    </div>
  )
}
export default ModalCloseBtn