import React from "react"
import "./ModalCloseCross.scss"

const ModalCloseCross = () => {
  return (
    <div id="_109">
      <svg className="_93_bt">
        <rect id="_93_bt" rx="0" ry="0" x="0" y="0" width="14.632" height="1.045">
        </rect>
      </svg>
      <svg className="_94_bu">
        <rect id="_94_bu" rx="0" ry="0" x="0" y="0" width="14.632" height="1.045">
        </rect>
      </svg>
    </div>
  )
}
export default ModalCloseCross