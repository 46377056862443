/**
 * Index.js
 * TOPページ
 */

// (1) import層
import React from "react"
// import { Link } from "gatsby"
import Seo from "../components/seo"
// import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Layout, FltShopBtn } from "../components/index"//サイト全体のコンポーネント
import { HeroImage, MainLead, AboutUs, Products, Location, Shop, News, Recruit, Recommend } from "../components/TopPage/index"//TOPページのコンポーネント
import "../styles/TopPage.scss"

// (2) Types層

// (3) DOM層
const Index = () => {
  // const breakpoints = useBreakpoint();

  return (
    <>
      <Layout className={`home`} pageName="home" title="home">
        <Seo
          lang="ja"
          title={`深川養鶏農業協同組合`}
          description="大自然豊かな山口県長門市にて、山口県産地鶏「黒かしわ」や「長州どり」などを育てる深川養鶏は、全国的にも珍しい養鶏専門の農協です。先進技術と長年の経験をもとに、鶏の生産に係る事業から鶏肉および加工食品の製造と製品販売等、多岐に渡る事業を展開しています。"
        />
        <HeroImage />
        <MainLead />
        <AboutUs />
        <section className={`Section`}>
          <Products />
        </section>
        <section className={`Section`}>
          <Location />
        </section>
        <section className={`Section`}>
          <Shop />
        </section>
        <section className={`Section`}>
          <News />
        </section>
        <section className={`Section`}>
          <Recruit />
        </section>
        <Recommend />
        <FltShopBtn />
      </Layout>
    </>
  )
}

export default Index

// (4) Style層

// (5) Container層

