import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const SingleList = ({ className }) => {
  const data = useStaticQuery(//ページコンポーネント以外ではuseStaticQueryを利用してgraphqlが利用できる
    graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
      }
    }
   allWpPost(sort: { fields: [modified] , order: DESC}
     skip: 0
     limit: 3 ) {
     edges {
       node {
         id
         title
         link
         date(formatString: "YYYY.MM.DD")
         modified(formatString: "YYYY/MM/DD")
         categories {
           nodes {
             name
           }
         }
         excerpt
         slug
         featuredImage {
           node {
             localFile {
               childImageSharp {
                 fluid(maxWidth: 1024) {
                   ...GatsbyImageSharpFluid_withWebp_tracedSVG
                 }
               }
             }
           }
         }
       }
     }
   }
 }
`
  )
  //色々とエラーが出ているので、無理にコンポーネント化せずにページコンポーネントの中へ入れておく、でOK
  return (
    <div className={className}>
      <ul>
        {data.allWpPost.edges.map(({ node }) => (
          <li key={node.title} className={`ListItem`}>
            <Link to={node.link}>
              <div className={`ListMeta`}>
                {/* <span>{node.categories.nodes[0].name}</span> */}
                <span>{node.date}</span>
              </div>
              <h3>{node.title}</h3>
            </Link>
          </li>
        ))}
      </ul>
    </div>

  )
}
export default SingleList;

