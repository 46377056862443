import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { ScrollComponent } from '../../index'
import * as styles from "./AboutUs.module.scss"
import { LinkButton } from "../../index"

const AboutUs = () => {
  return (
    <div className={styles.AboutUs}>
      <ul>
        <li>
          <ScrollComponent>
            <StaticImage
              src="../../../images/top/lead/top_lead03.png"
              alt="徹底した安全管理"
              placeholder="blurred"
            />
            <h3>徹底した安全管理</h3>
            <p>
              生産から加工・販売まで全ての
              工程を徹底した衛生管理のもと、
              自社で行っています。
            </p>
          </ScrollComponent>
        </li>
        <li>
          <ScrollComponent>

            <StaticImage
              src="../../../images/top/lead/top_lead04.png"
              alt="恵まれた環境と安全な飼料"
              placeholder="blurred"
            />
            <h3>恵まれた環境と<br />安全な飼料</h3>
            <p>
              鶏の飼料には抗生物質・合成抗菌剤不使用と5種類のハーブを使用した、独自の配合飼料を使用し、平飼いでのびのびと育てています。
            </p>
          </ScrollComponent>
        </li>
        <li>
          <ScrollComponent>
            <StaticImage
              src="../../../images/top/lead/top_lead05.png"
              alt="自然との調和"
              placeholder="blurred"
            />
            <h3>自然との調和</h3>
            <p>
              ふかわグループは、農業を通じて自然との調和を図りながら事業を発展させ、職員の夢の現実や地域社会への貢献をしてまいります。
            </p>
          </ScrollComponent>
        </li>
      </ul>
      <div className={styles.ButtonArea}>
        <LinkButton link="/business/" >事業内容</LinkButton>
        <LinkButton link="/companyoutline/" >会社案内</LinkButton>
      </div>
    </div>
  )
}
export default AboutUs