import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { ScrollComponent, TitleAnimation } from '../../index'
import { LinkButton } from "../../index"
import * as styles from "./Products.module.scss"
import TopProductsBg from "./TopProductsBg"
import TopProductsBgSp from "./TopProductsBgSp"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
//足跡
import BirdSteps from '../../BirdSteps/BirdSteps';
import stepsData from "../../BirdSteps/stepsData.json"


const Products = () => {
  //足跡アニメーションのデータを読み込む
  const steps = stepsData.step2

  const breakpoints = useBreakpoint();

  const title1 = [
    { "id": 0, "text": "p" },
    { "id": 1, "text": "r" },
    { "id": 2, "text": "o" },
    { "id": 3, "text": "d" },
    { "id": 4, "text": "u" },
    { "id": 5, "text": "c" },
    { "id": 6, "text": "t" },
    { "id": 7, "text": "s" }
  ];

  return (
    <>
      <div className={`svgBg`}>
        {breakpoints.sm ? <TopProductsBgSp /> : <TopProductsBg />}
      </div>
      <div className={`Section__inner`}>
        <ScrollComponent>
          <div className={styles.Products__wrp}>
            <div className={styles.Products}>
              <div className={styles.Lead}>
                <TitleAnimation data={title1} className={`titleRubik`} />
                <h2>
                  “あぁ、美味しい” と、<br />
                  五感が喜ぶ味が、<br />
                  ここにあります。
                </h2>
                <p>
                  鶏の生産をしている深川養鶏農業協同組合だから
                  できる、新鮮な鶏肉や卵を使った美味しい商品。
                  そのなかでも特に人気の商品を一部ご紹介いたします。
                  （掲載商品は深川養鶏オンラインショップでも
                  購入可能です。）
                </p>
              </div>
              <div className={styles.Img}>
                <StaticImage
                  src="../../../images/top/products/top_products-1.png"
                  alt="Products"
                  placeholder="blurred"
                />
              </div>
            </div>
            <LinkButton link="/product/">商品案内</LinkButton>
          </div>
        </ScrollComponent>
        <div className={styles.BirdSteps}>
          <BirdSteps data={steps} />
        </div>
      </div>
    </>
  )
}
export default Products