import React from "react";
import { StaticImage } from "gatsby-plugin-image"; //ローカルから画像を持ってくる
import { ScrollComponent, TitleAnimation } from "../../index";
// import { Link } from "gatsby"
import * as styles from "./Recommend.module.scss";

const Recommend = () => {
  const title1 = [
    { id: 0, text: "r" },
    { id: 1, text: "e" },
    { id: 2, text: "c" },
    { id: 3, text: "o" },
    { id: 4, text: "m" },
    { id: 5, text: "m" },
    { id: 6, text: "e" },
    { id: 7, text: "n" },
    { id: 8, text: "d" },
  ];

  return (
    <ScrollComponent>
      <div className={styles.Recommend}>
        <TitleAnimation data={title1} className={`titleRubikCenter`} />
        <h2 className={`subH2`}>おすすめリンク</h2>
        <div className={styles.contents}>
          <ul>
            <li>
              <a href="//www.chosyu-kurokashiwa.com/" target="_blank" rel="noreferrer">
                <StaticImage src="../../../images/top/bnr/bnr_kurokashiwa.png" alt="黒かしわ" placeholder="blurred" />
              </a>
            </li>
            <li className={styles.comming}>
              <a href="//chosyudori.com/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../../images/top/bnr/bnr_chosyudori02.png"
                  alt="山口県産 長州鶏"
                  placeholder="blurred"
                  className={styles.border}
                />
              </a>
            </li>
            <li>
              <a href="/chicken_heroes/index.html" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../../images/top/bnr/bnr_chiken_hero.PNG"
                  alt="チキンヒーローズ"
                  placeholder="blurred"
                  className={styles.border}
                />
              </a>
            </li> 
            {/* <li>
              <a href="//chosyudori.com/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../../images/top/bnr/bnr_chosyudori.png"
                  alt="長州どり"
                  placeholder="blurred"
                />
              </a>
            </li> */}
            <li>
              <a href="//www.chosyudori.net/" target="_blank" rel="noreferrer">
                <StaticImage
                  src="../../../images/top/bnr/bnr_shop@2x.png"
                  alt="オンラインショップ"
                  placeholder="blurred"
                />
              </a>
            </li>
          </ul>
          {/* 2022-04-01 キャンペーン終了に伴い撤去
          <div className={`${styles.obiBnr}`}>
            <a href="https://www.j-chicken.jp/campaign/quiz/" target="_blank" rel="noreferrer">
              <StaticImage src="../../../images/top/bnr/bnr_chicken1110.png" alt="チキンパワー" placeholder="blurred" />
            </a>
          </div> */}
        </div>
      </div>
    </ScrollComponent>
  );
};
export default Recommend;
