import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { ScrollComponent, TitleAnimation } from '../../index'
import * as styles from "./Shop.module.scss"
// import styled from "styled-components"
import TopShopBg from "./TopShopBg"
import TopShopBgSp from "./TopShopBgSp"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const Shop = () => {

  const breakpoints = useBreakpoint();

  const title1 = [
    { "id": 0, "text": "s" },
    { "id": 1, "text": "h" },
    { "id": 2, "text": "o" },
    { "id": 3, "text": "p" }
  ];

  return (
    <>
      <div className={`svgBg`}>
        {breakpoints.sm ? <TopShopBgSp /> : <TopShopBg />}
      </div>
      <div className={`Section__inner`}>
        <ScrollComponent>
          <div className={styles.Shop__wrp}>
            <div className={styles.Shop}>
              <div className={styles.Lead}>
                <TitleAnimation data={title1} className={`titleRubik`} />
                <h2>
                  製菓売店<br />
                  『トリーネふかわ』
                </h2>
                <p>
                  深川養鶏の工場隣に、イートインスペースがOPEN。<br />
                  焼きたてお菓子と香り豊かなコーヒーが
                  その場で楽しめる イートインスペースを完備。
                  深川養鶏でとれた新鮮な卵をつかった焼きたてお菓子と
                  香り豊かなコーヒーで贅沢なひとときを過ごしませんか。
                </p>
                <ul className={styles.info}>
                  <li><span>・</span> 住所：〒759-4101 山口県長門市東深川707-1</li>
                  <li><span>・</span> 駐車場：あり（20台）</li>
                  <li><span>・</span> 定休日：水・日曜</li>
                  <li><span>・</span> 電話番号：0837-22-2123</li>
                </ul>
              </div>
              <div className={styles.Img}>
                <StaticImage
                  src="../../../images/top/shop/top_shop-1.png"
                  alt="Shop1"
                  placeholder="blurred"
                />
                <StaticImage
                  src="../../../images/top/shop/top_shop-2.png"
                  alt="Shop2"
                  placeholder="blurred"
                />
              </div>
            </div>
          </div>
        </ScrollComponent>
      </div>
    </>
  )
}
export default Shop