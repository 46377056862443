import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { ScrollComponent, TitleAnimation } from '../../index'
// import {LinkButton} from "../../index"
import * as styles from "./Recruit.module.scss"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import TopRecruitBg from "./TopRecruitBg"
import TopRecruitBgSp from "./TopRecruitBgSp"

//足跡
import BirdSteps from '../../BirdSteps/BirdSteps';
import stepsData from "../../BirdSteps/stepsData.json"


const Recruit = () => {
  //足跡アニメーションのデータを読み込む
  const steps = stepsData.step1

  const title1 = [
    { "id": 0, "text": "r" },
    { "id": 1, "text": "e" },
    { "id": 2, "text": "c" },
    { "id": 3, "text": "r" },
    { "id": 4, "text": "u" },
    { "id": 5, "text": "i" },
    { "id": 6, "text": "t" },
  ];

  const breakpoints = useBreakpoint();

  return (
    <>
      <div className={`svgBg TopRecBg`}>
        {breakpoints.sm ? <TopRecruitBgSp /> : <TopRecruitBg />}
      </div>

      <div className={`Section__inner`}>
        <ScrollComponent>
          <div className={styles.Recruit}>
            <TitleAnimation data={title1} className={`titleRubikCenter`} />
            <h2 className={`subH2`}>採用情報</h2>
            <div className={styles.contents}>
              <Link to="/recruit/">
                {breakpoints.sm ?
                  <StaticImage
                    src="../../../images/top/bnr/bnr_recruit-sp.png"
                    alt="Recruit"
                    placeholder="blurred"
                  />
                  :
                  <StaticImage
                    src="../../../images/top/bnr/bnr_recruit.png"
                    alt="Recruit"
                    placeholder="blurred"
                  />
                }
              </Link>
            </div>
          </div>
        </ScrollComponent>
        <div className={styles.BirdSteps}>
          <BirdSteps data={steps} />
        </div>
      </div>
    </>
  )
}
export default Recruit