// (1) import層
import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import HeroBtm from "./HeroBtm"
import YoutubeModal from "./YoutubeModal"
import "./HeroImage.scss"
import ScrollAnime from "./ScrollAnime"
import { ScrollComponent } from '../../index'

const HeroImage = () => {
  const breakpoints = useBreakpoint();
  return (
    <div className="HeroImage">
      <StaticImage
        layout="fullWidth"
        src="../../../images/top/mv.png"
        alt="HeroImage"
      />
      <div className="TopLogo">
        <ScrollComponent>
          <StaticImage
            src="../../../images/header_logo.png"
            alt="深川養鶏農業協同組合"
            placeholder="tracedSVG"
          />
        </ScrollComponent>
      </div>
      <ScrollAnime />
      {breakpoints.sm ? null : <YoutubeModal />}
      <HeroBtm />
    </div>
  )
}
export default HeroImage