import React from "react"
import "./ScrollAnime.scss"

const ScrollAnime = () => {
  return (
    <div id="scroll_anime">
      <div id="scroll">
        <span>scroll</span>
      </div>
      <svg className="_23_h">
        <rect id="_23_h" rx="0" ry="0" x="0" y="0" width="2" height="83">
        </rect>
      </svg>
      <svg className="_18_h" viewBox="0 85.683 2 14.317">
        <path id="_18_h" d="M 0 85.6829833984375 L 2 85.6829833984375 L 2 100 L 0 100 L 0 85.6829833984375 Z">
        </path>
      </svg>
    </div>
  )
}
export default ScrollAnime