import React from "react"
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { ScrollComponent, TitleAnimation } from '../../index'
import * as styles from "./Location.module.scss"
//足跡
import BirdSteps from '../../BirdSteps/BirdSteps';
import stepsData from "../../BirdSteps/stepsData.json"


const Location = () => {
  //足跡アニメーションのデータを読み込む
  const steps = stepsData.step3

  const title1 = [
    { "id": 0, "text": "l" },
    { "id": 1, "text": "o" },
    { "id": 2, "text": "c" },
    { "id": 3, "text": "a" },
    { "id": 4, "text": "t" },
    { "id": 5, "text": "i" },
    { "id": 6, "text": "o" },
    { "id": 7, "text": "n" }
  ];

  return (
    <div className={`Section__inner`}>
      <ScrollComponent>
        <div className={styles.Location__wrp}>
          <div className={styles.Location}>
            <div className={styles.Img}>
              <StaticImage
                src="../../../images/top/location/top_location-1.png"
                alt="Location"
                placeholder="blurred"
              />
            </div>

            <div className={styles.Lead}>
              <TitleAnimation data={title1} className={`titleRubik`} />
              <h2>
                “美味しさ”を支える<br />
                大自然。
              </h2>
              <p>
                長州どりの主な生産地は山口県長門市近郊です。<br />
                長門市は大自然に囲まれた街で、海と山が調和した<br />
                美しい景色が認められ、北長門海岸国定公園に<br />
                指定されています。<br />
                青い海、澄みわたる空、静かで豊かな環境、
                そんな大自然に囲まれた長門市は、人だけではなく鶏を
                健康的に飼育するのにも良い影響を与えています。
              </p>
            </div>
          </div>
        </div>
      </ScrollComponent>
      <div className={styles.BirdSteps}>
        <BirdSteps data={steps} />
      </div>
    </div>
  )
}
export default Location