import React from 'react'
import "./RightFoot.scss";

const RightFoot = () => {
  return (
    <div className="id_34_jl">
      <svg className="_56_jm" viewBox="1293.175 582.095 12.703 11.11">
        <path className="id_56_jm" d="M 1298.814819335938 582.5853271484375 C 1298.089111328125 582.0179443359375 1297.945190429688 581.5234985351562 1296.8515625 583.6460571289062 C 1295.757934570312 585.7695922851562 1294.214111328125 589.34033203125 1293.473999023438 590.948974609375 C 1292.73388671875 592.5575561523438 1293.410278320312 594.4878540039062 1295.05078125 590.6920166015625 C 1296.691162109375 586.8950805664062 1297.463134765625 584.4991455078125 1297.784912109375 584.9812622070312 C 1298.106567382812 585.4642944335938 1299.296875 589.8553466796875 1299.972045898438 591.7528076171875 C 1300.647338867188 593.6512451171875 1301.7421875 593.8763427734375 1300.96923828125 591.3025512695312 C 1300.197143554688 588.7288208007812 1299.393432617188 585.4478759765625 1299.006958007812 584.74072265625 C 1298.62158203125 584.032470703125 1299.168334960938 583.9358520507812 1300.293823242188 584.9658203125 C 1301.42041015625 585.9946899414062 1303.4462890625 587.603271484375 1304.218139648438 588.4071044921875 C 1304.9912109375 589.2118530273438 1307.531982421875 589.6939086914062 1304.218139648438 586.8950805664062 C 1300.905517578125 584.0972900390625 1299.276245117188 582.946044921875 1298.814819335938 582.5853271484375 Z">
        </path>
      </svg>
      <svg className="_57_jn" viewBox="1296.427 579.055 1.375 2.66">
        <path className="id_57_jn" d="M 1296.760986328125 579.0662841796875 C 1296.182250976562 578.9450073242188 1296.472045898438 579.7745361328125 1296.760986328125 580.8034057617188 C 1297.050903320312 581.8333129882812 1298.03857421875 582.0584106445312 1297.749755859375 581.1250610351562 C 1297.4599609375 580.1928100585938 1297.37255859375 579.1958618164062 1296.760986328125 579.0662841796875 Z">
        </path>
      </svg>
    </div>
  )
}
export default RightFoot