import React from 'react'
import "./LeftFoot.scss";

const LeftFoot = () => {
  return (
    <div className="id_36_jr">
      <svg className="_60_js" viewBox="1277.824 596.822 13.085 11.424">
        <path className="id_60_js" d="M 1283.9130859375 597.420166015625 C 1284.548217773438 596.7540893554688 1284.618041992188 596.2432250976562 1286.0087890625 598.1848754882812 C 1287.398559570312 600.1275634765625 1289.444946289062 603.4362182617188 1290.4111328125 604.9204711914062 C 1291.376342773438 606.4036254882812 1290.98779296875 608.412109375 1288.813842773438 604.894775390625 C 1286.639892578125 601.3764038085938 1285.527709960938 599.1171264648438 1285.279052734375 599.641357421875 C 1285.031372070312 600.1655883789062 1284.49072265625 604.6829833984375 1284.098022460938 606.6596069335938 C 1283.705444335938 608.6351318359375 1282.656005859375 609.0164794921875 1283.046508789062 606.3583984375 C 1283.436157226562 603.7003784179688 1283.755737304688 600.3372192382812 1284.035278320312 599.5806884765625 C 1284.313842773438 598.82421875 1283.7587890625 598.8078002929688 1282.794677734375 599.9898071289062 C 1281.829467773438 601.171875 1280.05859375 603.0579223632812 1279.411010742188 603.9655151367188 C 1278.763427734375 604.8732299804688 1276.319091796875 605.7201538085938 1279.192016601562 602.4700317382812 C 1282.063842773438 599.219970703125 1283.508056640625 597.8446655273438 1283.9130859375 597.420166015625 Z">
        </path>
      </svg>
      <svg className="_61_jt" viewBox="1284.452 593.629 1.081 2.771">
        <path className="id_61_jt" d="M 1285.146850585938 593.6588134765625 C 1285.701782226562 593.4542236328125 1285.536254882812 594.317626953125 1285.398681640625 595.37841796875 C 1285.261840820312 596.4381103515625 1284.317260742188 596.8050537109375 1284.468383789062 595.8399047851562 C 1284.619506835938 594.874755859375 1284.560913085938 593.8756713867188 1285.146850585938 593.6588134765625 Z">
        </path>
      </svg>
    </div>
  )
}
export default LeftFoot