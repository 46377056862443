import React, { useState } from "react"
import Modal from "react-modal";
import { StaticImage } from "gatsby-plugin-image"//ローカルから画像を持ってくる
import { Youtube } from "../../../components/index"
// import { Movie } from "../../../components/index"
import { Button } from "../../index"
import ModalCloseCross from './ModalCloseCross'
import ModalCloseBtn from './ModalCloseBtn'
import * as styles from './YoutubeModal.module.scss'

// react-modalの初期設定
// root nodeのSelectorを設定
Modal.setAppElement("#___gatsby");

const modalStyle = {
  overlay: {
    zIndex: 15,
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    width: "100vw",
    height: "100vh"
  },
  content: {
    width: "100vw",
    height: "100vh",
    inset: 0,
    overflow: "hidden",
    border: "none",
    backgroundColor: "transparent"
  }
}

const YoutubeModal = () => {
  // モーダルの表示状態と切り替える為にState(props)を準備
  // false = 非表示、数値 = 表示しているModalの番目とする
  const [modalIsOpen, setIsOpen] = useState(false);

  //どのモーダルを表示するのか操作するために関数を準備
  const handleOpenModal = (num) => {
    setIsOpen(num)
  }

  // shouldCloseOnEscやshouldCloseOnOverlayCliceを使う場合に設定が必要
  // モーダルを非表示の状態にするため、falseを指定する
  const handleCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <>
      <div className={`YoutubeModal`}>
        <button onClick={() => handleOpenModal(0)}>
          <span className={`Balloon`}>movie</span>
          <StaticImage
            width={97.5}
            src="../../../images/top/icon_movie.png"
            alt="Youtube Modal Icon"
            placeholder="tracedSVG"
          />
        </button>
      </div>
      <Modal
        style={modalStyle}
        isOpen={(modalIsOpen === 0)}
        onRequestClose={() => handleCloseModal()}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
      >
        <button className={`${styles.ModalCloseBtn}`} onClick={() => handleCloseModal()}>
          <ModalCloseBtn />
        </button>
        <div className={`${styles.YoutubeView}`}>
          <StaticImage
            className={`${styles.YoutubeTitle}`}
            src="../../../images/footer_logo.png"
            alt="FUKAWA"
            placeholder="tracedSVG"
          />
          <Youtube
            className={`YoutubeWrp`}
            width="621"
            height="349"
            videoSrcURL="https://www.youtube.com/embed/LUGFNwW1QpY"
            videoTitle="深川養鶏農業協同組合　 企業ＰＲ動画"
          />
          {/* <div className={`${styles.MovieArea}`}>
            <Movie className={`${styles.YoutubeWrp}`} />
          </div> */}
          <div className={`${styles.YoutubeCaption}`}>
            <span>深川養鶏協同組合 紹介movie</span>
          </div>
          <Button className={`${styles.ModalClose}`} onClick={() => handleCloseModal()}>
            close<ModalCloseCross />
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default YoutubeModal