import React, { useState, useEffect, useRef } from 'react';
import LeftFoot from './LeftFoot'
import RightFoot from './RightFoot'
import styled from 'styled-components'
import * as styles from "./BirdSteps.module.scss"

const BirdSteps = ({ data }) => {
  //スクロールイベント
  const target = useRef(null);
  const [classNames, setClassNames] = useState([styles.item, styles.scroll]);
  useEffect(() => {
    const targetTopPosition = target.current.getBoundingClientRect().top;
    const showTarget = () => {

      const TriggerMargin = window.innerHeight * 0.05;//ターゲット要素がWindowの下から30%の位置まで来たら

      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition > targetTopPosition + TriggerMargin) {
        setClassNames([styles.item, styles.scrollShow])
      } else {
        setClassNames([styles.item, styles.scroll])
      }
    }
    showTarget();
    const onScroll = () => {
      showTarget();
    }
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll, { passive: true });
  }, []);
  return (
    <Ul>
      {data.map((step, i) => (//mapの引数の２つ目は0が初期値のindexになる: 0,1,2,3,....
        <Li key={i} index={i} ref={target} className={classNames.join(" ")} posX={step.positionX} posY={step.positionY}>
          {(step.foot === "right") ? <RightFoot /> : <LeftFoot />}
        </Li>
      ))}
    </Ul>
  )
}
export default BirdSteps

const Ul = styled.ul`
  position: relative;
`
const INTERVAL_SEC = 0.35;
/**
 * styledコンポーネントで定義したshowSidenaviとindexを変数として使う。
 * ${({変数1, 変数２})}という感じで複数いれて最初に書くことも可能
 * if文は、「変数 ? trueのときの値 : falseのときの値」
 */
const Li = styled.li`
  position: absolute;
  list-style: none;
  transition: all 1s ease-in-out;
  ${({ index, posX, posY }) => `
    left: ${posX};
    top: ${posY};
    transition-delay: ${INTERVAL_SEC * index}s;
  `}
`